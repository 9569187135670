.side-menu_logo {
  margin-top: 1rem;
  padding: 1.5rem 3rem;
  img {
    width: 100%;
  }
}

.sideMenu {
  padding-top: 0 !important;
  &_item {
    &_icon.MuiListItemIcon-root {
      color: #fff;
      min-width: unset;
      padding-right: 0.5rem;
    }
  }
  &_item.MuiListItem-root.Mui-selected,
  &_item.MuiListItem-root.Mui-selected:hover {
    background-color: #532242;
  }
  &_border-left.Mui-selected {
    border-left: 6px solid #24d7d3;
  }
  &_border-right.Mui-selected {
    border-right: 6px solid #24d7d3;
  }
  &_nested-ar {
    padding-right: 2rem !important;
  }
  &_nested-en {
    padding-left: 2rem !important;
  }
}

.sideMenu_item_icon {
  width: 2rem;
}

.rtl .sideMenu_item_icon.MuiListItemIcon-root {
  // transform: rotate(0deg) !important;
  margin-left: 10px !important;
}

body[dir="rtl"] .sideMenu_item span {
  font-size: 0.9rem !important;
}

.fz-09 span {
  font-size: 0.9rem;
}

.name_bullet_container {
  display: flex;
  align-items: center;
}

.notification_bullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #24d7d3;
  border-radius: 50%;
}