@import '../scss/variables';

body {
  margin                 : 0;
  font-family            : "Cairo-Regular";
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  color                  : #2d2d2d;
  background-color       : #f8f9fd !important;

  .cyan-button,
  .cyan-button:hover {
    background-color: $cyan;
    padding         : 5px 30px;
    box-shadow      : 0px 3px 6px #00000029;
    color           : white;
    border-radius   : 25px;
    border-color    : transparent;

    @media(max-width: 821px) {
      width: 110px;
      padding: 5px 15px;
      font-size: 10px;
    }
    
  }

  .center-vertical {
    vertical-align: middle;
    top           : 50%;
    -ms-transform : translateY(-50%);
    transform     : translateY(-50%);
    position      : absolute;
  }

  .primary-color {
    color: $primaryColor
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-size: 16px;

  @include media-breakpoint-down(md) {
    font-size: 12px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 10px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Cairo-Bold;
  color      : #2d2d2d;
}

a {
  color: inherit;

  &:hover {
    color          : inherit;
    text-decoration: none;
  }
}

.form-submit-btn {
  border-radius: 2.5rem !important;
}

.dark-cyan-color {
  color: #55d8fe;
}

.light-cyan-color {
  color: #24d7d3;
}

.light-purple-color {
  color: #4564f9,
}
.gray-color{
  color:#c7c7c7
}
.cursor-pointer{
  cursor: pointer;
}

.clipped {
  white-space  : nowrap;
  overflow     : hidden;
  text-overflow: ellipsis;
  line-height  : normal;
}
@media (max-width: 768px) {
  main {
    margin-left: 165px;
    margin-right: unset;
  }
}
label.MuiInputLabel-formControl {
  font-size: 0.9rem;
  white-space: nowrap;
}
@media (max-width: 900px) {
  .MuiFormControl-fullWidth {
    overflow: hidden;
  }
  label.MuiInputLabel-formControl {
    font-size: 10px;
    white-space: nowrap;
  }
}
.MuiTableCell-body {
  color: #666;
}

/* Customize the label (the container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 14px !important;
  margin-top: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333 !important;
  white-space: nowrap;
}

body[dir="rtl"] .checkbox-container {
  padding-left: 0;
  padding-right: 35px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #6a2a53;
  border-radius: 4px;
  background-color: #fff;
}

body[dir="rtl"] .checkmark {
  left: initial;
  right: 0;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid #6a2a53;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-container input:disabled ~ .checkmark {
  border: 2px solid #a16e8f;
}

.checkbox-container input:disabled ~ .checkmark:after {
  border-color: #a16e8f;
}



  /* RADIO BOX */
  .radiobox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .radiobox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom radio button */
  .radiobox-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .radiobox-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .radiobox-container input:checked ~ .checkmark {
    background-color: #6A2A53;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .radiobox-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .radiobox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .radiobox-container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }

  /* STATUS COLORS */@at-root
  
.status-label {
  position: absolute;
  top: 7px;
  left: 80%;
  border-radius: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: #FFF;
  padding: 5px 10px;
  font-size: 10px;
  white-space: nowrap;
}

.status0 {
  border-top: 3px solid #EEE;
  border-bottom: 3px solid #EEE;
  border-left: 3px solid #EEE;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #BBB;
  }
}

.status1 {
  border-top: 3px solid #FCA420;
  border-bottom: 3px solid #FCA420;
  border-left: 3px solid #FCA420;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #FCA420;
  }
}

.status2 {
  border-top: 3px solid #2436D7;
  border-bottom: 3px solid #2436D7;
  border-left: 3px solid #2436D7;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #2436D7;
  }
}

.status3 {
  border-top: 3px solid #6A2A53;
  border-bottom: 3px solid #6A2A53;
  border-left: 3px solid #6A2A53;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #6A2A53;
  }
}

.status4 {
  border-top: 3px solid #E41700;
  border-bottom: 3px solid #E41700;
  border-left: 3px solid #E41700;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #E41700;
  }
}

.status5 {
  border-top: 3px solid #0E4766;
  border-bottom: 3px solid #0E4766;
  border-left: 3px solid #0E4766;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #0E4766;
  }
}

.status6 {
  border-top: 3px solid #33941E;
  border-bottom: 3px solid #33941E;
  border-left: 3px solid #33941E;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #33941E;
  }
}

.status7 {
  border-top: 3px solid #fc8320;
  border-bottom: 3px solid #fc8320;
  border-left: 3px solid #fc8320;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #fc8320;
  }
}

.status8 {
  border-top: 3px solid #ce2519;
  border-bottom: 3px solid #ce2519;
  border-left: 3px solid #ce2519;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #ce2519;
  }
}

.status9 {
  border-top: 3px solid #652a6a;
  border-bottom: 3px solid #652a6a;
  border-left: 3px solid #652a6a;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #652a6a;
  }
}

.status10 {
  border-top: 3px solid #d43397;
  border-bottom: 3px solid #d43397;
  border-left: 3px solid #d43397;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #d43397;
  }
}

.status11 {
  border-top: 3px solid #0e6649;
  border-bottom: 3px solid #0e6649;
  border-left: 3px solid #0e6649;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #0e6649;
  }
}

.status12 {
  border-top: 3px solid #67941e;
  border-bottom: 3px solid #67941e;
  border-left: 3px solid #67941e;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #67941e;
  }
}

.status13 {
  border-top: 3px solid #14afb4;
  border-bottom: 3px solid #14afb4;
  border-left: 3px solid #14afb4;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #14afb4;
  }
}

.status14 {
  border-top: 3px solid #b4875c;
  border-bottom: 3px solid #b4875c;
  border-left: 3px solid #b4875c;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #b4875c;
  }
}

.status15 {
  border-top: 3px solid #e9c04f;
  border-bottom: 3px solid #e9c04f;
  border-left: 3px solid #e9c04f;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #e9c04f;
  }
}

.status16 {
  border-top: 3px solid #cc6ed4;
  border-bottom: 3px solid #cc6ed4;
  border-left: 3px solid #cc6ed4;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #cc6ed4;
  }
}

.status17 {
  border-top: 3px solid #5d5fd1;
  border-bottom: 3px solid #5d5fd1;
  border-left: 3px solid #5d5fd1;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #5d5fd1;
  }
}

.status18 {
  border-top: 3px solid #a09537;
  border-bottom: 3px solid #a09537;
  border-left: 3px solid #a09537;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #a09537;
  }
}

.status19 {
  border-top: 3px solid #a34123;
  border-bottom: 3px solid #a34123;
  border-left: 3px solid #a34123;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #a34123;
  }
}

.status20 {
  border-top: 3px solid #23b67d;
  border-bottom: 3px solid #23b67d;
  border-left: 3px solid #23b67d;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #23b67d;
  }
}

.status21 {
  border-top: 3px solid #aeb63c;
  border-bottom: 3px solid #aeb63c;
  border-left: 3px solid #aeb63c;
  border-right: 3px solid #EEE;

  .status-label {
      background-color: #aeb63c;
  }
}


.schedule_row {
  display: flex;
  justify-content: space-between;
}

.schedule_col_6 {
  width: 50%;
}


.schedule_subrow_start {
  display: flex;
  justify-content: flex-start;
}

.schedule_subrow_end {
  display: flex;
  justify-content: flex-end;
}

.schedule_first_col {
  width: 110px;
}

.auto_complete_input_container input {
  margin: 0 !important;
}

.mt-6 {
  margin-top: 32px !important;
}

.MuiTable-root.Table-table {
  min-width: auto !important;
}

.MainLayout-dayScaleEmptyCell {
  width: 80px !important;
  min-width: 80px !important;
}

.not_auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  color: #c5380d;
}