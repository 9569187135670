.header-wrapper {
  color: #2d2d2d;
  display: flex;
  padding: 1.5rem 2rem;
  justify-content: space-between;
  background-color: #f8f9fd;
  z-index: 9999;
  &_content {
    display: flex;
    &_date {
      display: flex;
      align-items: center;
      &_icon {
        width: 1.50rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
      &_text {
        font-size: 0.9rem;
        font-weight: bold;
        margin-bottom: 0;
      }
    }
    &_notifications {
      position: relative;
      display: flex;
      align-items: center;
      position: relative;
      margin-left: 2rem;
      margin-right: 2rem;
      cursor: pointer;
      &_icon.MuiSvgIcon-root {
        font-size: 2.25rem;
        cursor: pointer;
      }
      &_count {
        font-size: 0.65rem;
        background-color: #F07272;
        color: #fff;
        margin-bottom: 0;
        position: absolute;
        bottom: 2px;
        right: -7px;
        border-radius: 50%;
        width: 1.25rem;
        height: 1.25rem;
        text-align: center;
        line-height: 1.25rem;
      }
    }
    &_profile-link {
      background-color: #bed8fb;
      width: 2.75rem;
      height: 2.75rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      &_icon {
        width: 45px;
        height: 45px;
        border-radius: 50%;
      }
      &_icon.MuiSvgIcon-root {
        font-size: 2.25rem;
        color: #8b8b8b;
      }
    }
  }
}

.notifications-modal {
  &_container{ 
    position: absolute;
    top: 110%;
    right: 10%;
    width: 360px;
    height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    transform: scale(0);
    pointer-events: none;
    z-index: -1;

    &.show {
      opacity: 1;
      transform: scale(1);
      pointer-events: all;
      z-index: 999;
    }

    &.rtlPosition {
      right: auto;
      left: 10%;
    }

    & h4 {
      margin: 15px;
    }

    &_content {
      margin-bottom: 1rem;

      &_item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        padding-left: 10px;
        opacity: 0.8;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        border-radius: 2px;

        &:hover {
          background-color: #f7f7f7;
          opacity: 1;
        }

        &_message {
          font-size: 15px;
          color: #333;
          line-height: 1.1rem;
          width: 77%;
          padding: 20px 10px;
          border-radius: 2px;
        }

        &_date {
          font-size: 14px;
          color: #8b8b8b;
          width: 20%;
          padding: 20px 5px;
          text-align: center;
          position: relative;

          &_full {
            position: absolute;
            bottom: 2px;
            right: 0;
            font-size: 11px;
            color: #8b8b8b;
            width: 103%;
            text-align: center
          };
        }

        &_redDot {
          width: 3%;
          display: flex;
          justify-content: center;
          align-items: center;

          & > span {
            display: block;
            width: 8px;
            height: 8px;
            background-color: #9e84da;
            border-radius: 50%;
            transition: all 0.8s;
            opacity: 0;

            &.show {
              opacity: 1;            
            }
          }
        }

        &.rtlFlex {
          padding-left: 0;
          padding-right: 10px;
          & > div { 
            direction: rtl;
            text-align: right;
          }
        }
      }
    }
  } 
}

.MuiToolbar-gutters.MuiToolbar-regular {
  z-index: 9999;
  // background-color: #f8f9fd;
}
