.mobile_side_menu {
  display: none !important;
}

.main_contant {
  display: flex;
  &_header.MuiAppBar-colorPrimary {
    background-color: transparent;
    box-shadow: unset;
  }
}

@media only screen and (max-width: 768px) {
  .mobile_side_menu {
    display: block !important;
  }

  .desktop_side_menu {
    display: none !important;
  }

  .main_contant {
    display: block;
  }
}
