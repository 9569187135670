@import "./variables";

.scrollbar {
    width: 100%;
    height       : 65vh;
    overflow-y   : scroll;
    background   : transparent;
    overflow-y   : scroll;
    margin-bottom: 10px;
    scrollbar-width:thin;
    scrollbar-color:$primaryColor #F5F5F5;
}

  @media(min-width: 1900px) {
    .scrollbar {
      height: 70vh;
    }
  }


#style-4::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color  : #F5F5F5;
}

#style-4::-webkit-scrollbar {
    width           : 5px;
    height:5px;
    background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar-thumb {
    background-color: $primaryColor;
    border          : 2px solid $primaryColor;
}