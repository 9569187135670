.toggle-wrapper.MuiToggleButtonGroup-root {
  border-radius: 2rem;
  background: #efefef;
  height: 2.2rem;
  .MuiToggleButton-root {
    border: unset;
    border-radius: 50%;
    padding: unset;
    font-size: 0.9rem;
    width: 2.2rem;
    height: 2.2rem;
    &:focus {
      outline: unset;
    }
  }
  .MuiToggleButton-root.Mui-selected {
    background-color: #6a2a53;
    color: #fff;
  }
}
