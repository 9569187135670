.rtl {
  text-align: right;
  .sideMenu {
    padding-top: 0 !important;
  }
  .input .MuiInputBase-formControl {
    .eye-icon {
      right: unset;
      left: 0;
    }
  }
  .card-wrapper {
    width: 180px;
  }
  @media (max-width: 768px) {
    main {
      margin-right: 165px;
      margin-left: unset;
    }
  }
  .input .MuiInputBase-formControl {
    .eye-icon {
      right: unset;
      left: 0;
    }
  }
  .card-wrapper {
    width: 180px;
  }
  @media (max-width: 768px) {
    main {
      margin-right: 165px;
      margin-left: unset;
    }
  }
}
