::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgb(207, 207, 207);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(207, 207, 207);
}
