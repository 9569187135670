@import 'bootstrap/scss/bootstrap';
@import './variables';
@import './fonts';
@import './rtl';
@import './general';
@import './scroll';
@import './scrollbar';

body {
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

header {
    padding-right: 0 !important;
    padding-left: 0 !important;
}


