@import '../styles/main.scss';

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 20px;
}

@media(max-width: 768px) {
    .footer {
        padding: 20px 0 20px 0;
    }
}

body[dir='rtl'] {
    .footer {
        padding: 20px 240px 20px 0;
    }
}

.MuiInput-underline:before, .MuiInput-underline:after {
    border: none !important;
}

@media(min-width: 1900px) {
   .application-container {
       min-height: 81vh !important;
   }
}

.MuiInputAdornment-positionEnd {
    position: absolute;
    right: 0;
    top: 25px;
}

select:focus>option:checked,
select:focus>option:hover {
    background: #eee !important;
    background-color: #eee !important;
}

select {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 9px;

    option {
        border-radius: 0 !important;
    }
}

body[dir='rtl'] select {
    background-position-x: 0;
}

body[dir='rtl'] .MuiInputAdornment-positionEnd {
    right: auto;
    left: 0;
}

.grid_no_data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55vh;
    font-size: 1.2rem;
    color: #444;
}

@media(min-width: 1900px) {
    .grid_no_data {
        height: 75vh;
        font-size: 1.3rem;
    }
}