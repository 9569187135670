@font-face {
  font-family: "Cairo-Regular";
  src: url("../assets/fonts/Cairo-Regular.ttf");
}
@font-face {
  font-family: "Cairo-Bold";
  src: url("../assets/fonts/Cairo-Bold.ttf");
}
@font-face {
  font-family: "Cairo-SemiBold";
  src: url("../assets/fonts/Cairo-SemiBold.ttf");
}
